import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import Styles from "../../../../src/features/call/ui/styles/grid";
import Styles2 from '../ui/styles/Call.styles'
import VideoStreamComponent from "../../../features/call/ui/components/VideoStreamComponent";
import BottomDivMain from "../../../features/call/ui/components/BottomDivMain";
import SidebarLayout from "../../../features/call/ui/components/SidebarLayout";
import SidebarMembers from "../../../features/call/ui/components/SidebarMembers";
import SidebarInfo from "../../../features/call/ui/components/SidebarInfo";
import SidebarShare from "../../../features/call/ui/components/SidebarShare";
import SidebarChat from "../../../features/call/ui/components/SidebarChat";
import SidebarTranscribe from "./components/SidebarTranscribe";
import MobileSidebar from "./components/MobileSidebar";
import { useNavigate, useLocation } from "react-router-dom";
import { roomSlice } from "../store/slice";
import { generalSlice } from "../../../domain/SharedSlices/generalslice/generalSlice";
import { BaseContext } from "../../../app/BaseConext";
import "../ui/styles/index.css"
import { BiCopyright } from "react-icons/bi";
import EndOrLeaveModal from "./modules/endOrLeaveModal";
import SpeechToText from "../../../domain/modules/SpeechToText";
import { message } from "antd";
import { updateApprovalMembers } from "../store/actions";
import SidebarStatus from "./components/SidebarStatus";


function Intialize() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = useLocation().search;
  const roomId = new URLSearchParams(search).get("room");

  const JoinCallState = useSelector(
    ({ joinCallSlice: { uiState } }) => uiState
  );
  const { callstarted, userId, email, userName, adminIds, accessToken } = useSelector(
    ({ generalSlice }) => generalSlice
  );
  const audioOn = useSelector(
    ({ roomSlice: { users }, generalSlice }) =>
      users[generalSlice?.userId]?.uiState?.audioOn
  );
  const isTranscribe = useSelector(({ roomSlice }) => {
    return roomSlice.uiState.isTranscribe;
  });

  const { MyStreams, SocketConnection } = useContext(BaseContext);
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [option, setOption] = useState("");


  const Loading = useSelector(
    ({ roomSlice: { uiState } }) => uiState.Loading
  );

  const { MainContainer, MainDiv, SidebarView, MobileSidebarView, SideBarCopyRightTag } = Styles;
  const { Spinner, LoadingSpinIcon } = Styles2;

  const sideBarMapping = {
    sidebarstatus: <SidebarStatus onClickSidebarClose={onClickSidebarClose} />,
    sidebarlayout: <SidebarLayout onClickSidebarClose={onClickSidebarClose} />,
    sidebarmembers: <SidebarMembers onClickSidebarClose={onClickSidebarClose} />,
    sidebarinfo: <SidebarInfo onClickSidebarClose={onClickSidebarClose} />,
    sidebarshare: <SidebarShare onClickSidebarClose={onClickSidebarClose} />,
    sidebarchat: <SidebarChat onClickSidebarClose={onClickSidebarClose} SocketConnection={SocketConnection} />,
    sidebartranscribe: <SidebarTranscribe onClickSidebarClose={onClickSidebarClose} sidebar={sidebar} SocketConnection={SocketConnection} />,
  };

  const chatListLength = useSelector(({ roomSlice }) => {
    return roomSlice.uiState.chatList.length;
  });
  const notificationMessage = useSelector(({ roomSlice }) => {
    return roomSlice.uiState.notificationMessage;
  });
  function openMobileSidebar() {
    setMobileSidebar(!mobileSidebar);
  }

  useEffect(() => {
    if (option === 'sidebarchat') {
      if (sidebar) dispatch(roomSlice.actions.updateTempNotificationLength());
    }
  }, [chatListLength, sidebar]);

  function openSidebar(sideBarOption, viewType) {
    setOption(sideBarOption);
    if (!sidebar) setSidebar(!sidebar);
    if (!viewType) {
      if (option === sideBarOption) setSidebar(!sidebar);
    }
    if (sideBarOption === 'sidebarchat') dispatch(roomSlice.actions.updateChatViewed(sidebar));
  }
  function onClickSidebarClose() {
    setSidebar(!sidebar);
    dispatch(roomSlice.actions.updateChatViewed(true));
  }
  function onClickMobileSidebarClose() {
    setMobileSidebar(false);
  }
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (Object.keys(notificationMessage).length) {
      messageApi.open(notificationMessage);
    }
  }, [notificationMessage])


  useEffect(() => {
    let mount = true;
    dispatch(updateApprovalMembers(roomId))

    if (mount) {
      if (!callstarted) return navigate(`/${roomId}`);
      MyStreams.getInstance().then(async ({ audioStream, videoStream }) => {
        SocketConnection.getSocket().then(async (socketId) => {
          await SocketConnection.joinRoom({
            userName,
            userId,
            audioOn: JoinCallState.audioOn,
            cameraOn: JoinCallState.cameraOn,
            roomId,
            email,
            audioStream,
            videoStream,
          }).then(() => {
            // console.log("thired",callstarted)
            dispatch(
              roomSlice.actions.addUserData({
                userId, email, socketId, userName
              })
            );
            dispatch(
              roomSlice.actions.addUserVideo({
                video: JoinCallState.cameraOn, userId
              })
            );
            dispatch(
              roomSlice.actions.addUserAudio({
                audio: JoinCallState.audioOn, userId
              })
            );
            dispatch(roomSlice.actions.callStarted());
          });
        });
      });
    }
    dispatch(roomSlice.actions.updateIsTranscribe(true))
    return () => {
      mount = false;
      dispatch(roomSlice.actions.LeaveCall());
      dispatch(generalSlice.actions.callEnded());
      // console.log("Destoryed")
    };
  }, []);
  if (Loading) return <Spinner indicator={<LoadingSpinIcon />} />;


  return (
    <MainContainer>
      {contextHolder}
      <SpeechToText SocketConnection={SocketConnection} isTranscribe={isTranscribe} />
      <EndOrLeaveModal
        useSelector={useSelector}
        dispatch={dispatch}
        navigate={navigate}
        SocketConnection={SocketConnection}
        userId={userId}
        adminIds={adminIds}
      />
      <MainDiv>
        <VideoStreamComponent />
        <BottomDivMain
          openSidebar={openSidebar}
          openMobileSidebar={openMobileSidebar}
        />
      </MainDiv>
      <SidebarView className={sidebar ? "sideBar Open" : "sideBar"}>
        {sideBarMapping[option]}
        <SideBarCopyRightTag>
          <BiCopyright /> Workwise - Copyrights @2016-2024
        </SideBarCopyRightTag>
      </SidebarView>
      <MobileSidebarView className={mobileSidebar ? "sideBar Open" : "sideBar"}>
        <MobileSidebar
          onClickMobileSidebarClose={onClickMobileSidebarClose}
          openSidebar={openSidebar}
        />
      </MobileSidebarView>

    </MainContainer>
  );
}

export default Intialize;
