import Styles from "../styles/grid";
import { FaHandPaper } from "react-icons/fa";
import brbicon from "../../../../assets/brb.png";
import dndicon from "../../../../assets/dnd.png";
import { useSelector, useDispatch } from "react-redux";
import { roomSlice } from "../../store/slice";
import { statusColors } from "../../../../app/helper";
import { useContext } from "react";
import { BaseContext } from "../../../../app/BaseConext";

function SidebarStatus({ onClickSidebarClose }) {
    const { SocketConnection } = useContext(BaseContext)
    const dispatch = useDispatch();


    const handRaisedIds = useSelector(
        ({ generalSlice }) => generalSlice.handRaisedIds
    );
    const roomStatuses = useSelector(
        ({ generalSlice }) => generalSlice.roomStatuses
    );
    const myId = useSelector(
        ({ generalSlice }) => generalSlice.userId
    );

    const {
        Sidebar,
        SidebarHeading,
        SidebarHeader,
        SidebarHeaderButton,
        SidebarHR,
        SidebarMemberBody
    } = Styles;

    const rowStyle = (status) => ({
        display: "flex",
        gap: "20px",
        marginLeft: "10px",
        marginRight: "10px",
        marginBottom: "10px",
        padding: "8px",
        borderRadius: "10px",
        alignItems: "center",
        background: "rgb(112, 112, 112)",
        border: selectedStatus === status ? `2px solid ${statusColors[status]}` : "2px solid transparent",
        cursor: "pointer"
    });

    const selectedStatus = useSelector(({ roomSlice }) => {
        return roomSlice.uiState.selectedStatus;
    });


    const handleStatusClick = async (status) => {
        if (selectedStatus === status) {
            if (status === 'raiseHand') {
                SocketConnection.NotifyRaiseHand(false, handRaisedIds, myId)
            }
            SocketConnection.NotifyRoomStatus(null, roomStatuses, myId)
            return;
        }
        else {
            if (status === 'raiseHand') {
                SocketConnection.NotifyRaiseHand(true, handRaisedIds, myId)
            }
            else {
                SocketConnection.NotifyRaiseHand(false, handRaisedIds, myId)
            }
            SocketConnection.NotifyRoomStatus(status, roomStatuses, myId)
            return;
        }
    };

    return (
        <Sidebar>
            <SidebarHeader>
                <SidebarHeading> Status </SidebarHeading>
                <SidebarHeaderButton onClick={onClickSidebarClose}>X</SidebarHeaderButton>
            </SidebarHeader>
            <SidebarHR style={{ marginTop: "0px" }} />
            <SidebarMemberBody style={{ marginBottom: "8%" }} >
                <div
                    style={rowStyle("raiseHand")}
                    onClick={() => handleStatusClick("raiseHand")}
                >
                    <FaHandPaper color="#ffff3a" size={18} />
                    <div style={{ color: "white", fontSize: "14px" }}>Raise Hand</div>
                </div>
                <div
                    style={rowStyle("brb")}
                    onClick={() => handleStatusClick("brb")}
                >
                    <img src={brbicon} alt="icon" style={{ width: 25, height: 20 }} />
                    <div style={{ color: "white", fontSize: "14px" }}>Be Right Back</div>
                </div>
                <div
                    style={rowStyle("dnd")}
                    onClick={() => handleStatusClick("dnd")}
                >
                    <img src={dndicon} alt="icon" style={{ width: 22, height: 20 }} />
                    <div style={{ color: "white", fontSize: "14px" }}>Do Not Disturb</div>
                </div>
            </SidebarMemberBody>
        </Sidebar>
    );
}

export default SidebarStatus;
