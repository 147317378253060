
import Styles from "../styles/grid";
import { useState, useEffect } from "react";
import { IoPersonAddSharp } from "react-icons/io5";
import { RiAdminFill } from "react-icons/ri";
import { Alert, Button, Switch, message } from 'antd';
import SidebarMemberRowComponent from "./SidebarMemberRowComponent";
import { CapitalizeName, capitalizeWordsInArray } from "../../../../app/helper";
import AddPartcipantModal from "../modules/addParticipantModal";
import { ParticipantApproval } from "./SidebarMemberApproval";
import { useMediaQuery } from "react-responsive";
import { roomSlice } from "../../store/slice";
import { useDispatch, useSelector } from "react-redux";
import { updateRoom } from "../../../../data/api/Api";

function SidebarMembers(props) {
    const dispatch = useDispatch();
    const [members, setMembers] = useState([])
    const isGalaxyFolded = useMediaQuery({ maxWidth: 290 });
    const [disableSwitch, setDisableSwitch] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const users = useSelector(({ roomSlice: { users } }) => users);
    const { userId, adminIds, accessToken, roomId } = useSelector(
        ({ generalSlice }) => generalSlice
    );
    const meetingApprovalSwitch = useSelector(
        ({ roomSlice: { uiState } }) => uiState.meetingApprovalSwitch
    );
    useEffect(() => {
        setMembers(Object.keys(users))
    }, [users])

    // useEffect(() => {
    //     console.log("meetingApprovalSwitch: ", meetingApprovalSwitch);
    // }, [meetingApprovalSwitch])

    const handleLockSwitch = async () => {
        if (approvalMembers.length) {
            alert("Please resolve all pending approval requests before proceeding.")
            return
        }
        setDisableSwitch(true);
        dispatch(roomSlice.actions.updateMeetingApprovalSwitch(!meetingApprovalSwitch))
        const data = { "meetingApprovalSwitch": !meetingApprovalSwitch }
        const response = await updateRoom(roomId, data, accessToken)
        if (!response.data.updated) {
            alert("Unexpected Error in Locking Room ");
            dispatch(roomSlice.actions.updateMeetingApprovalSwitch(!meetingApprovalSwitch))
        }
        // SocketConnection.NotifyRoomLockUpdate(data)
        setDisableSwitch(false);

    }

    const {
        SidebarHeaderRow,
        SidebarHeading,
        SidebarHeader,
        SidebarHeaderButton,
        SidebarHR,
        SidebarMemberBody,
        Sidebar,
    } = Styles;
    const { onClickSidebarClose } = props;


    const [showAddParticipantsModal, setShowAddParticipantsModal] = useState(false);

    const approvalMembers = useSelector(({ roomSlice }) => {
        return roomSlice.uiState.approvalMembers;
    });

    return (
        <Sidebar>
            {contextHolder}
            {showAddParticipantsModal ?
                <AddPartcipantModal
                    showAddParticipantsModal={showAddParticipantsModal}
                    setShowAddParticipantsModal={setShowAddParticipantsModal}
                    messageApi={messageApi}
                />
                : <></>
            }

            <SidebarHeader>
                <SidebarHeading> Participants </SidebarHeading>
                <SidebarHeaderButton onClick={() => { onClickSidebarClose() }}>X</SidebarHeaderButton>
            </SidebarHeader>
            <SidebarHR style={{ marginTop: "0px" }} />
            {
                adminIds?.includes(userId) &&
                <>
                    <div style={{
                        marginRight: "15px",
                        marginLeft: "15px",
                        marginBottom: "10px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                        <div style={{ color: "white", fontSize: isGalaxyFolded ? "14px" : "17px", fontWeight: "400" }}>
                            Joining Approval
                        </div>
                        <div>
                            <Switch
                                style={
                                    meetingApprovalSwitch ?
                                        { backgroundColor: "#1A5669" } :
                                        { color: "white" }
                                }
                                size={isGalaxyFolded ? "small" : "default"}
                                checkedChildren="On"
                                unCheckedChildren="Off"
                                checked={meetingApprovalSwitch}
                                onChange={handleLockSwitch}
                                disabled={disableSwitch} />
                        </div>
                    </div>
                    <SidebarHR style={{ marginTop: "0px" }} />
                </>
            }
            {
                (adminIds?.includes(userId) && approvalMembers.length) ?
                    <>
                        <SidebarMemberBody style={{ marginBottom: "15px" }}>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    margin: "10px",
                                    padding: "5px",
                                    color: "white",
                                    fontWeight: "500",
                                    fontSize: "17px",
                                    backgroundColor: "rgb(26, 86, 105)",
                                    borderRadius: "5px",

                                }}
                            >Participants Approval
                            </div>
                            {
                                approvalMembers.map((member) => {
                                    // console.log(member);
                                    if (adminIds?.includes(userId) && member.approval == 2) {
                                        return <ParticipantApproval
                                            key={member.email}
                                            email={member.email}
                                        />
                                    }

                                })
                            }
                        </SidebarMemberBody>
                        <SidebarHR style={{ marginTop: "0px" }} />
                    </>
                    : <></>
            }
            {
                adminIds?.includes(userId) ?
                    <SidebarHeaderRow justify="center" style={{ display: "flex", flexDirection: "row" }} >
                        <Button type="primary" size={"large"}
                            onClick={() => setShowAddParticipantsModal(true)}
                            style={{
                                display: "flex", alignItems: "center",
                                justifyContent: "center", gap: "10px", width: "95%", border: "none"
                            }}>
                            <IoPersonAddSharp size="15px" />
                            <div >Add Participants</div>
                        </Button>
                        {/* <Button type="primary" size={"large"}
                            onClick={() => { }}
                            style={{
                                display: "flex", alignItems: "center",
                                justifyContent: "center", gap: "10px", width: "45%", border: "none"
                            }}>
                            <RiAdminFill size="17px" />
                            <div >Add Hosts</div>
                        </Button> */}
                    </SidebarHeaderRow>
                    : ""
            }

            {/* <SidebarHeaderRow justify="center">
                <SidebarHeaderInput type="text" placeholder="Search Participants" ></SidebarHeaderInput>
            </SidebarHeaderRow>  */}
            <SidebarMemberBody style={{ marginBottom: "15px" }}>
                {
                    members.map((memberId) => {
                        if (memberId.endsWith("_screen_share")) return;
                        return <SidebarMemberRowComponent
                            key={memberId}
                            memberId={memberId}
                            userId={userId}
                            name={CapitalizeName(users[memberId]?.userName)}
                            email={userId == memberId ? "You" : users[memberId]?.email}
                            admin={adminIds?.includes(memberId) ? true : false}
                            adminIds={adminIds || []}
                        />
                    })
                }
            </SidebarMemberBody>

        </Sidebar>
    );
}


export default SidebarMembers;