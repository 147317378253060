import Styles from "../styles/grid";
import Styles2 from "../styles/Call.styles";
import "../styles/index.css";
import React, { useState, useRef, useEffect, useContext } from "react";
import { BsPinAngle, BsPin } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { roomSlice } from "../../store/slice";
import { BaseContext } from "../../../../app/BaseConext";
import { Badge, Space, Switch } from 'antd';
import { FaHandPaper } from "react-icons/fa";
import { statusColors } from "../../../../app/helper";
import brbicon from "../../../../assets/brb.png"
import dndicon from "../../../../assets/dnd.png"

const VideoStreamTagComponent = ({ userId, style, sidebarlayout, colCount }) => {
  const [displayEmail, setdisplayEmail] = useState(false);
  const { MyStreams, SocketConnection } = useContext(BaseContext);
  const dispatch = useDispatch();
  const videoStream = useRef();
  const screenType = useRef();

  const {
    handRaiseCount,
    NameTag,
    EmailTag,
    NameEmailTag,
    VideoStreamTag,
    CircleDiv,
    VideoText,
    NotVideoStreamTag,
    PinTag,
    VideoStreamParentdiv,
    NameTagSideBarLayout
  } = Styles;
  const { ButtonView2 } = Styles2;

  const isPin = useSelector(({ roomSlice }) => {
    return roomSlice.users[userId]?.uiState?.pin;
  });
  const viewType = useSelector(({ roomSlice }) => {
    return roomSlice.uiState.viewType;
  });
  const selectedStatus = useSelector(({ roomSlice }) => {
    return roomSlice.uiState.selectedStatus;
  });
  const userName = useSelector(
    ({ roomSlice }) => roomSlice.users[userId]?.userName
  );
  const email = useSelector(
    ({ roomSlice }) => roomSlice.users[userId]?.email
  );
  const camera = useSelector(
    ({ roomSlice }) => roomSlice.users[userId]?.uiState?.cameraOn
  );
  const myId = useSelector(
    ({ generalSlice }) => generalSlice.userId
  );
  const isHandRised = useSelector(({ roomSlice }) => {
    return roomSlice.uiState.isHandRised;
  });

  useEffect(() => {
    screenType.current =
      viewType === 1
        ? 8
        : viewType === 2
          ? 6
          : viewType === 3
            ? 4
            : viewType === 4
              ? 4
              : 8;
    // console.log("child component - useEffect - view type")
  }, [viewType]);
  const currentVideoDeviceIndex = useSelector(
    ({ roomSlice: { uiState } }) => uiState.currentVideoDeviceIndex
  );
  const videoDevices = useSelector(
    ({ roomSlice: { uiState } }) => uiState.videoDevices
  );
  const pinCount = useSelector((state) => state.roomSlice.uiState.pinCount);
  const users = useSelector(({ roomSlice: { users } }) => users);

  useEffect(() => {
    let mount = true;
    if (mount) {
      // console.log(userId, "=======", pinCount, sidebarlayout)
      SocketConnection.Streams.getUserStreams(userId)
        .then(async ({ video }) => {
          if (camera) {
            videoStream.current.srcObject = video;
          }
        })
        .catch((error) => { console.error(error) });
    }
    return () => {
      mount = false;
    };
  }, [videoStream, camera, currentVideoDeviceIndex]);

  const handRaisedIds = useSelector(
    ({ generalSlice }) => generalSlice.handRaisedIds
  );
  const roomStatuses = useSelector(
    ({ generalSlice }) => generalSlice.roomStatuses
  );
  const [status, setStatus] = useState(null);


  useEffect(() => {
    setStatus(roomStatuses.find((user) => user.userId == userId))
  }, [roomStatuses]);


  function handlePinClick() {
    dispatch(roomSlice.actions.updatePinCount({ isPin: !isPin }));
    dispatch(roomSlice.actions.setPin({ userId: userId, isPin: !isPin }));
  }
  function handleChange() {
    setdisplayEmail(!displayEmail);
  }

  const checkUserRaiseHandForStyle = () => {
    if (handRaisedIds.includes(userId)) {
      return {
        ...style, ...{ border: `2px solid ${statusColors['raiseHand']}` }
      }
    }
    // if (selectedStatus && selectedStatus !== 'raiseHand') {
    //   console.log("SELECTED STATUS: ", selectedStatus);
    //   return {
    //     ...style, ...{ border: `2px solid ${statusColors[selectedStatus]}` }
    //   }
    // }
    if (status) {
      return {
        ...style, ...{ border: `2px solid ${statusColors[status.status]}` }
      }
    }
    return style
  }
  const displayName = () => {
    return (
      myId === userId ? 'You' : userName
    )
  }
  const displayStatus = () => {
    if (handRaisedIds.includes(userId)) {
      return (
        <Badge
          count={handRaisedIds.indexOf(userId) + 1}
          size={"small"}
          offset={[18, 0]}
          style={{
            color: "black",
            background: "yellow",
            border: "none"
          }}>
          <div style={{ color: "white" }}>
            {displayName()}
            <FaHandPaper color="#ffff3a" size={15} style={{ marginLeft: '5px' }} />
          </div>
        </Badge>
      )
    }
    if (status?.status === 'brb') {
      return (
        <div >
          {displayName()}
          <img src={brbicon} alt="icon" style={{ width: 20, height: 15, marginLeft: '5px' }} />
        </div>
      )
    }
    if (status?.status === 'dnd') {
      return (
        <div >
          {displayName()}
          <img src={dndicon} alt="icon" style={{ width: 18, height: 16, marginLeft: '5px' }} />
        </div>
      )
    }
    return displayName()
  }

  const displayNameTag = () => {
    return (
      <NameTag className="NameTag" onClick={handleChange} colCount={colCount} >
        {
          displayStatus()
        }
      </NameTag>
    )
  }
  const displayEmailTag = (video = false) => {
    return (
      displayEmail &&
      <EmailTag video={video} colCount={colCount} className="EmailTag">
        {email}
      </EmailTag>
    )
  }
  return (
    <>
      {camera ? (
        <VideoStreamParentdiv className="main-container">
          <VideoStreamTag
            userId={userId}
            myId={myId}
            currentDeviceIndex={currentVideoDeviceIndex}
            style={checkUserRaiseHandForStyle()}
            ref={videoStream}
            autoPlay playsInline loop />
          {
            !sidebarlayout ?
              <NameEmailTag video={true} colCount={colCount} className="NameEmailTag">
                {
                  displayNameTag()
                }
                {
                  displayEmailTag(true)
                }
              </NameEmailTag>
              :
              <NameTagSideBarLayout>
                {
                  myId === userId ? 'You' : userName
                }
              </NameTagSideBarLayout>
          }
          <PinTag className="pinBoxVideo" sidebarlayout={sidebarlayout}>
            <ButtonView2 onClick={handlePinClick}>
              {isPin ? (
                <BsPin size={sidebarlayout ? "15px" : "20px"} color="white" />
              ) : (
                <BsPinAngle size={sidebarlayout ? "15px" : "20px"} color="white" />
              )}
            </ButtonView2>
          </PinTag>
        </VideoStreamParentdiv>
      ) : (

        <NotVideoStreamTag className="main-container" style={checkUserRaiseHandForStyle()} >
          {
            !sidebarlayout ?
              <NameEmailTag video={false} colCount={colCount} className="NameEmailTag">
                {
                  displayNameTag()
                }
                {
                  displayEmailTag(false)
                }
              </NameEmailTag>
              :
              <NameTagSideBarLayout>{myId === userId ? 'You' : userName}</NameTagSideBarLayout>
          }
          <CircleDiv sidebarlayout={sidebarlayout} style={checkUserRaiseHandForStyle()}>
            <PinTag className="pinBox" sidebarlayout={sidebarlayout}>
              <ButtonView2 onClick={handlePinClick}>
                {isPin ? (
                  <BsPin size={sidebarlayout ? "15px" : "20px"} color="white" />
                ) : (
                  <BsPinAngle size={sidebarlayout ? "15px" : "20px"} color="white" />
                )}
              </ButtonView2>
            </PinTag>
            <VideoText>{userName ? userName[0].toUpperCase() : ''}</VideoText>
          </CircleDiv>
        </NotVideoStreamTag>

      )}
    </>
  );
};
export default VideoStreamTagComponent;
