import { createSlice } from "@reduxjs/toolkit";
import { windowType } from "../../../app/utils/enums";
import roomActions, { toggleVideoTracks, toggleAudioTracks, toggleCamera, updateApprovalMembers } from "./actions";

const initialState = {
  users: {
  },
  uiState: {
    selectedStatus: null,
    isHandRised: false,
    showEndMeetingModal: false,
    showAddParticipantsModal: false,
    viewType: windowType.Desktop,
    pinCount: 0,
    alert: false,
    waitingCamera: false,
    alertMessage: "",
    Loading: true,
    isScreenShare: false,
    isMileBoardShare: false,
    screenShareAllow: true,
    chatList: [],
    isChatBarClosed: true,
    tempNotificationLength: 0,
    isLock: false,
    meetingApprovalSwitch: true,
    currentVideoDeviceIndex: 0,
    videoDevices: [],
    currentOutputDevice: 0,
    outputDevices: [],
    isTranscribe: false,
    transcribeList: [],
    notificationMessage: {},
    notificationApproval: 0,
    approvalMembers: []
  },
  userIds: [],
};

export const roomSlice = createSlice({
  name: "roomSlice",
  initialState: initialState,
  reducers: roomActions,
  extraReducers: {
    [toggleVideoTracks.pending]: (state, _) => {
      state.uiState.waitingCamera = true;
    },
    [toggleVideoTracks.rejected]: (state, payload) => { },
    [toggleVideoTracks.fulfilled]: (state, { payload: { cameraOn } }) => {

      if (cameraOn) {
        state.users[Object.keys(state.users)[0]].uiState.cameraOn = true;
      } else {
        state.users[Object.keys(state.users)[0]].uiState.cameraOn = false;
      }
      state.uiState.waitingCamera = false;
    },

    [toggleCamera.pending]: (state) => {
      state.uiState.waitingCamera = true;
    },
    [toggleCamera.rejected]: (state, payload) => { },
    [toggleCamera.fulfilled]: (state, { payload: { cameraOn, newIndex } }) => {
      if (cameraOn) {
        state.users[Object.keys(state.users)[0]].uiState.cameraOn = true;
      } else {
        state.users[Object.keys(state.users)[0]].uiState.cameraOn = false;
      }
      state.uiState.waitingCamera = false;
      state.uiState.currentVideoDeviceIndex = newIndex;
      // state.uiState.selectedDeviceId = deviceId;
    },

    [toggleAudioTracks.pending]: (state, _) => { },
    [toggleAudioTracks.rejected]: (state, payload) => { },
    [toggleAudioTracks.fulfilled]: (state, { payload: { audioOn } }) => {
      if (audioOn) {
        state.users[Object.keys(state.users)[0]].uiState.audioOn = true;
      } else {
        state.users[Object.keys(state.users)[0]].uiState.audioOn = false;
      }
    },

    [updateApprovalMembers.pending]: (state, _) => { },
    [updateApprovalMembers.rejected]: (state, payload) => { },
    [updateApprovalMembers.fulfilled]: (state, { payload }) => {
      state.uiState.approvalMembers = payload
    },

  },
});

export default roomSlice.reducer;
